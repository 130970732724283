import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showAddTicketPopup,
  showTicketDetailsPopup,
  hideTicketDetailsPopup,
  setTickets,
  hideAddTicketPopup,
} from "../../Pages/Store";
import AddTicket from "./AddTicket";
import TicketDetails from "./TicketDetails";
import { useNavigate } from "react-router-dom";
import { apiUrl, statusColors } from "../../Pages/Config";
import "../../Styles/SelfService.css";
// import { FiLogOut } from "react-icons/fi";
import { toast } from "react-toastify";
import useIdleTimer from "../../Pages/UseIdleTimer";

const SelfService = () => {
  const navigate = useNavigate();
  const [ticketNumber, setTicketNumber] = useState("");
  const [sortValue, setSortValue] = useState("All");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const tickets = useSelector((state) => state.root.tickets);
  const dispatch = useDispatch();
  const {
    showAddTicketPopup: showAddTicket,
    showTicketDetailsPopup: showTicketDetails,
    ticketDetails,
  } = useSelector((state) => state.root);
  const login = localStorage.getItem("Login");

  const handleLogout = () => {
    navigate("/login");
    localStorage.removeItem("Login");
  };

  useIdleTimer(handleLogout, 900000);

  useEffect(() => {
    if (!login) {
      handleLogout();
    }
  }, [login]);

  useEffect(() => {
    if (tickets.length === 0) {
      handleData();
    }
    dispatch(hideTicketDetailsPopup());
    dispatch(hideAddTicketPopup());
  }, []);

  const handleData = async () => {
    setLoading(true);
    try {
      const ticketResponse = await fetch(
        `${apiUrl}fetchUserTickets/${localStorage.getItem("Id")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      const fetchResponse = await ticketResponse.json();
      if (fetchResponse.message && fetchResponse.message.includes("Account tickets not found")) {
        dispatch(setTickets([]));
      } else{
        const data = fetchResponse.filter(
          (project) =>
            project.product_name &&
            project.product_name.toLowerCase() === "frothcrm"
        );
        dispatch(setTickets(data));
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
      dispatch(setTickets([]));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSortChange({ target: { value: sortValue } });
  }, [tickets]);

  useEffect(() => {
    // Define the base URL and website token
    const BASE_URL = "https://chat.frothdesk.com/";
    
    // Create the script element
    const script = document.createElement("script");
    script.src = BASE_URL + "/packs/js/sdk.js";
    script.async = true;
    script.defer = true;

    // Append the script to the document
    document.body.appendChild(script);

    // Initialize the chat widget after script is loaded
    script.onload = () => {
      window.chatwootSDK.run({
        websiteToken: 'YgrzgAapzXKeeNyXBGn3A55q',
        baseUrl: BASE_URL
      });
    };

    // Cleanup: Remove the script if the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    paginateTickets();
  }, [filteredTickets, currentPage, pageSize]);

  const handleSortChange = (event) => {
    const selectedValue = event.target.value;
    setSortValue(selectedValue);

    if (tickets.length === 0) {
      setFilteredTickets([]);
    } else if (selectedValue === "All") {
      setFilteredTickets(tickets);
    } else {
      const filteredData = tickets.filter((ticket) => {
        const statusParts = ticket.status.split("_");
        const mainStatus =
          statusParts.length > 1 ? statusParts[1] : ticket.status;
        return mainStatus === selectedValue;
      });
      setFilteredTickets(filteredData);
    }
    setCurrentPage(1);
  };

  const searchTicket = () => {
    if (ticketNumber.trim()) {
      const searchTicket = tickets.find(
        (ticket) => String(ticket.case_number) === ticketNumber.trim()
      );

      if (searchTicket) {
        dispatch(showTicketDetailsPopup(searchTicket));
      } else {
        toast.warn("Ticket not found", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      setTicketNumber("");
    } else {
      toast.warn("Enter case number to search", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const totalTickets = tickets.length;
  let openTickets = tickets.filter((ticket) =>
    ticket.status.includes("Open")
  ).length;

  const paginateTickets = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const sortedTickets = filteredTickets.sort(
      (a, b) => b.case_number - a.case_number
    );
    return sortedTickets.slice(startIndex, endIndex);
  };

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      searchTicket();
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredTickets.length / pageSize);

  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1, 2);

      if (currentPage > 6 && currentPage < totalPages - 2) {
        pageNumbers.push("...");
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
        pageNumbers.push("...");
      } else if (currentPage <= 3) {
        pageNumbers.push(3, 4);
        pageNumbers.push("...");
      } else {
        pageNumbers.push("...");
        pageNumbers.push(totalPages - 3, totalPages - 2);
      }
      pageNumbers.push(totalPages - 1, totalPages);
    }

    return (
      <nav>
        <ul className="pagination justify-content-center mt-3 mt-md-0">
          <li
            className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &laquo;
            </button>
          </li>
          {pageNumbers.map((number, index) => (
            <li
              key={index}
              className={`page-item ${
                number === currentPage ? "active" : ""
              }`}
            >
              {number === "..." ? (
                <span className="page-link">{number}</span>
              ) : (
                <button
                  className="page-link"
                  onClick={() => handlePageChange(number)}
                >
                  {number}
                </button>
              )}
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="container-fluid" style={{ padding: "35px 18px 35px 35px" }}>
      <div className="container-fluid mb-3" style={{padding:"0"}}>
        <div className="row">
        <div className="d-flex justify-content-between align-items-center">
            <div className="col-md-6 d-flex flex-wrap align-items-center">
              <div className="me-3 mb-2 mb-md-0">
                <b>Name:</b> {localStorage.getItem("name")}
              </div>
              <div className="mb-2 mb-md-0">
                <b>Email:</b> {localStorage.getItem("email")}
              </div>
            </div>
            <button className="col-md-1 btn btn-light add-btn" onClick={handleLogout} style={{ textWrap: "nowrap" }}>
              Logout
            </button>
          </div>
        </div>
        <div className="row mb-4 mt-3">
          <form className="d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="d-flex flex-column flex-md-row align-items-center mb-3 mb-md-0">
              <input
                type="text"
                className="col-md-4 form-control mb-2 mb-md-0 me-md-2"
                id="ticketNumber"
                placeholder="Enter case number..."
                value={ticketNumber}
                onKeyDown={handleSearch}
                onChange={(e) => setTicketNumber(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-light mb-2 mb-md-0 me-2 add-btn"
                onClick={searchTicket}
                style={{ width: "8rem" }}
              >
                Search
              </button>
              <button
                type="button"
                className="btn btn-light mb-2 mb-md-0 me-2 add-btn"
                onClick={() => dispatch(showAddTicketPopup())}
                style={{ width: "8rem" }}
              >
                + New Ticket
              </button>
              <div className="form-group mb-2 mb-md-0" style={{ width: "8rem" }}>
                <select
                  className="form-select"
                  id="sortSelect"
                  onChange={handleSortChange}
                  data-toggle="tooltip" data-placement="top"
                title="Filter by Status"
                  value={sortValue}
                >
                  <option value="All">All</option>
                  <option value="Assigned">Assigned</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Closed">Closed</option>
                  <option value="New">New</option>
                  <option value="Pending">Pending</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Resolved">Resolved</option>
                  <option value="Duplicate">Duplicate</option>
                </select>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center">
              <div className="ms-0 ms-md-3 mb-2 mb-md-0">
                <b>Total Tickets:</b> {totalTickets}
              </div>
              <div className="ms-0 ms-md-3">
                <b>Open Tickets:</b> {openTickets}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="table-responsive mb-4">
        
        {!loading && (totalTickets === 0 || filteredTickets?.length ===0) ? (
          <div className="text-center">No tickets available</div>
        ) : (
          <>
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">SL NO</th>
                  <th scope="col">Case Number</th>
                  <th scope="col">Case Title</th>
                  <th scope="col">Raised On</th>
                  <th scope="col">Assigned To</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {paginateTickets().map((ticket, index) => {
                  const caseNumber = ticket.case_number;
                  const statusParts = ticket.status?.split("_");
                  const mainStatus = statusParts ? statusParts[1] : "";
                  const statusColor =
                    statusColors[mainStatus] || statusColors.Default;

                  return (
                    <tr key={index}>
                      <td>{(currentPage - 1) * pageSize + index + 1}</td>
                      <td>
                        <a
                          id="user-button"
                          onClick={() => dispatch(showTicketDetailsPopup(ticket))}
                        >
                          {caseNumber}
                        </a>
                      </td>
                      <td>{ticket.name}</td>
                      <td>{ticket.date_entered?.split(" ")[0]}</td>
                      <td>{ticket.assigned_user_name || '-'}</td>
                      <td>
                        <button
                          className="status"
                          style={{
                            backgroundColor: statusColor,
                            width: "100%",
                            cursor: "default",
                            border: "none",
                            color: "white",
                            padding: "5px",
                          }}
                          disabled
                        >
                          {mainStatus}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div id="loadingSpinner" style={{ display: "none" }}>
              <i className="fa-solid fa-spinner fa-3x mx-auto d-block"></i>
            </div>
          </>
        )}
        {loading && (
      <div class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>)}
      </div>
      <div className="Pagination">
        <div className="d-flex  flex-column flex-md-row align-items-center justify-content-between gap-3 mb-3 text-center" style={{ textWrap: 'nowrap' }}>
          <span>Items per page</span>
          <select
            className="form-select"
            value={pageSize}
            onChange={handlePageSizeChange}
            style={{ width: 'auto' }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </div>
      {showAddTicket && <AddTicket />}
      {showTicketDetails && ticketDetails && (
        <TicketDetails ticketDetails={ticketDetails} />
      )}
    </div>
  );
};

export default SelfService;
